<template>
  <div class="page-wrapper"></div>
</template>

<script>
import { db } from '@/js/rtdb'
// import { db } from '@/db'

export default {
  data() {
    return {
      home: []
    }
  },
  mounted() {
    db.ref('WebApp/Page_Home').once('value', snapshot => {
      this.home = snapshot.val()
    })
  }
}
</script>
