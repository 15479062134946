<template>
  <div class="page-wrapper">
    <Chatroom :room-i-d="roomID"></Chatroom>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Chatroom from '@/components/Chatroom'

export default {
  components: { Chatroom },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('products', ['getProductById'])
    // currentProduct() {
    //   return this.getProductById(this.id)
    // }
  }
}
</script>
