import Vue from 'vue'
import VueGtag from 'vue-gtag'
import rtdbPlugin from 'vuefire'

import App from './App.vue'
import router from './router'
import store from './store'
import '@/misc/register-service-worker'
import '@/misc/handle-network-status'
// import '@/firebase/init'
// import '@/firebase/authentication'
// import '@/misc/handle-apple-install-prompt'
import 'pwacompat'

Vue.use(
  VueGtag,
  {
    config: {
      id: 'UA-25778484-2'
    },
    appName: 'Afterlife'
  },
  router,
  rtdbPlugin
)

// Vue.use(VueAnalytics, {
//   id: 'UA-25778484-2'
// })

Vue.config.productionTip = false

// eslint-disable-next-line
const vm = new Vue({
  router,
  store,
  // browserDetect,
  // components: { Unity },
  render: h => h(App)
}).$mount('#app')

global.vm = vm

// eslint-disable-next-line
function UnityMessengerText(text) {
  console.log(text)
}

// Gloable Event Test
vm.$on('onUnityMessage', msg => {
  console.log(msg)
})
