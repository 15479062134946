<template>
  <div class="webgl-content">
    <div id="unity-container" :class="{ loaded: loaded }"></div>
    <!-- <transition name="fade"> -->
    <!-- <div v-if="loaded == false"> -->
    <div
      v-if="!externalProgress"
      class="progress-bar"
      :class="{ loaded: loaded }"
    >
      <div class="progress-fill" :style="{ width: progress * 100 + '%' }"></div>
    </div>
    <!-- </div> -->
    <!-- </transition> -->
    <div v-if="hideFooter !== true" class="footer">
      <a class="fullscreen" @click.prevent="fullscreen">Fullscreen</a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Event from '@/js/event.js' //eslint-disable-line

export default {
  props: {
    src: String,
    unityLoader: String,
    hlsLoader: String,
    hideFooter: Boolean,
    externalProgress: Function,
    module: Object
  },
  data() {
    return {
      gameInstance: null,
      loaded: false,
      progress: 0,
      error: null
    }
  },

  beforeMount() {
    if (!this.eventBus) {
      this.eventBus = new Vue({
        data: {
          ready: false,
          load: false
        }
      })
    }
    if (
      typeof UnityLoader === 'undefined' &&
      this.unityLoader &&
      !this.eventBus.load
    ) {
      const script = document.createElement('SCRIPT')
      script.setAttribute('src', this.unityLoader)
      script.setAttribute('async', '')
      script.setAttribute('defer', '')
      document.body.appendChild(script)

      const hlsScript = document.createElement('SCRIPT')
      hlsScript.setAttribute('src', this.hlsLoader)
      document.body.appendChild(hlsScript)

      this.eventBus.load = true
      console.log('👾beforeMount: createElement script')
      script.onload = () => {
        this.eventBus.ready = true
        this.eventBus.$emit('onload')
        console.log('👾beforeMount: script.onoad')
      }
      script.UnityMessengerText = text => {
        this.eventBus.ready = true
        this.eventBus.$emit(text)
        console.log('👾UnityMessengerText: '.text)
      }
    } else {
      this.eventBus.ready = true
      this.eventBus.load = true
      console.log('👾beforeMount: script ready')
    }
  },

  mounted() {
    console.log('👾!!!mounted')
    const instantiate = () => {
      if (typeof UnityLoader === 'undefined') {
        const error =
          'The UnityLoader was not defined, please add the script tag ' +
          'to the base html and embed the UnityLoader.js file Unity exported or use "unityLoader" attribute for path to UnityLoader.js.'
        console.error(error)
        this.error = error
        return
      }
      if (this.src === null) {
        const error =
          'Please provice a path to a valid JSON in the "src" attribute.'
        console.error(error)
        this.error = error
        return
      }
      const params = {}
      params.onProgress = this.onProgress
      if (this.module) {
        params.Module = this.module
      }
      params.devicePixelRatio = 1

      // eslint-disable-next-line
      this.gameInstance = UnityLoader.instantiate(
        'unity-container',
        this.src,
        params
      )
    }
    if (this.eventBus.ready) {
      console.log('👾 mounted: try instantiate ready')
      instantiate()
    } else {
      this.eventBus.$on('onload', () => {
        console.log('👾 mounted: try instantiate onload')
        instantiate()
      })
    }

    window.addEventListener(
      'OnGameStart',
      () => {
        this.loaded = true
        console.log('👾 OnGameStart')
        this.onGameStarted()
      },
      false
    )
    window.addEventListener(
      'OnGameEnd',
      () => {
        console.log('👾 OnGameEnd')
        this.onGameEnd()
      },
      false
    )

    window.addEventListener('resize', this.onWindowResize)
  },
  // End Mounted

  methods: {
    setPointerLock() {
      // const canvas = document.querySelector('canvas')
      // canvas.addEventListener(
      //   'click',
      //   e => {
      //     // console.log('👾OnGameEnd')
      //     // this.onGameEnd()
      //     console.log('👾canvas setPointerLock', e)
      //     canvas.requestPointerLock =
      //       canvas.requestPointerLock ||
      //       canvas.mozRequestPointerLock ||
      //       canvas.webkitRequestPointerLock ||
      //       canvas.msRequestPointerLock
      //     canvas.requestPointerLock()

      //     // 1) Used as a boolean check: are we pointer locked?
      //     if (document.pointerLockElement) {
      //       console.log('👾locked')
      //     } else {
      //       // pointer is not locked
      //       console.log('👾not locked')
      //     }
      //   },
      //   false
      // )

      document.addEventListener(
        'webkitpointerlockchange',
        () => {
          console.log('👾webkitpointerlockchange')
        },
        false
      )
    },
    fullscreen() {
      this.gameInstance.SetFullscreen(1)
      // document.body.requestFullscreen()
    },
    message(gameObject, method, param) {
      if (param === null) {
        param = ''
      }
      if (this.gameInstance !== null) {
        this.gameInstance.SendMessage(gameObject, method, param)
      } else {
        console.warn(
          "vue-unity-webgl: you've sent a message to the Unity content, but it wasn\t instantiated yet."
        )
      }
    },
    // eslint-disable-next-line
    onProgress(gameInstance, progress) {
      this.loaded = progress === 1
      this.progress = progress
      // console.log(this.progress)
      if (this.externalProgress) {
        this.externalProgress(progress)
      }
      if (progress === 1) this.onDownloaded()
    },
    onWindowResize() {
      // Limit the maxs window width to 1920, preventing heigh pixel density from performance problems
      // const canvas = document.querySelector('canvas')
      // const widthLimit = 1920
      // if (window.devicePixelRatio > 1 || window.innerWidth > widthLimit) {
      //   if (window.innerWidth * window.devicePixelRatio > widthLimit) {
      //     canvas.width = widthLimit
      //     canvas.height = (widthLimit / window.innerWidth) * window.innerHeight
      //   } else {
      //     canvas.width = window.innerWidth
      //     canvas.height = window.innerHeight
      //   }
      // }
      // console.log(
      //   '👾 checkCanvasSize ',
      //   window.devicePixelRatio,
      //   window.innerWidth,
      //   window.innerHeight,
      //   canvas.width,
      //   canvas.height
      // )
    },
    onDownloaded() {
      this.$emit('onDownloaded')
    },
    onGameStarted() {
      const canvas = document.querySelector('canvas')
      canvas.addEventListener(
        'click',
        () => {
          this.onClickCanvas()
        },
        false
      )
      // this.setPointerLock()
      this.onWindowResize()
      this.$emit('onGameStarted')
    },
    onGameEnd() {
      this.$emit('onGameEnd')
    },
    onClickCanvas() {
      // this.showIntro
      console.log('👾 onClickCanvas ')
    }
  }
}
</script>

<style lang="scss">
#unity-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  transition: 2s;
  // transition-delay: 3s;
  canvas {
    min-width: 100vw;
    min-height: 100vh;
  }

  &.loaded {
    opacity: 1;
  }
}
</style>

<style lang="scss" scoped>
.webgl-content {
  .progress-bar {
    position: fixed;
    width: 100%;
    height: 10px;
    bottom: 0;
    left: 0;
    z-index: 5;
    .progress-fill {
      height: 100%;
      background: #555;
      transition: width 0.1s;
    }
    &.loaded {
      opacity: 0;
      transition: 1s;
      transition-delay: 1s;
      .progress-fill {
        width: 100% !important;
      }
    }
  }
}
</style>
