<template>
  <div class="page-wrapper">
    <unity
      v-if="canLoadGame"
      id="WebglPlayer"
      ref="UnityRef"
      class="layout-fullview"
      :src="src"
      :unity-loader="uloader"
      :hls-loader="hlsloader"
      :external-progress="onProgress"
      @click.native="onUnityFocus"
      @onGameEnd="onGameEnd"
      @onDownloaded="onDownloaded"
      @onGameStarted="onGameStarted"
    ></unity>

    <div class="progress-bar" :class="{ isGameStarted: isGameStarted }">
      <div v-if="!isGameStarted" class="progress-bar">
        <div
          class="progress-fill"
          :style="{ width: downloadProgress * 100 + '%' }"
        ></div>
      </div>
    </div>

    <transition name="fade" :duration="3000">
      <div v-if="showBG" id="MainBg" class="overlay-bg layout-fullview">
        <div class="bg-Content">
          <!-- <video
            v-if="gameSupported"
            src="static/Video/OB_WebBG_60fps.mp4"
            autoplay
            loop
            muted
          ></video>
          <video
            v-else-if="!gameSupported"
            src="static/Video/OB_PR.mp4"
            autoplay
            loop
            muted
          ></video> -->
          <video
            src="https://storage.googleapis.com/afterlife-dev/_SteamVideo/AFUN-PreviewBG-2k.mp4"
            autoplay
            loop
            muted
          ></video>
          <div class="logo">
            <img
              v-if="isMobile"
              class="icon"
              src="@/assets/img/logo_mobile.png"
            />
            <img v-else class="icon" src="@/assets/img/logo.png" />
          </div>
        </div>
      </div>
    </transition>

    <!-- <div v-if="countBool===true"> -->
    <div v-if="webglSupport">
      <div v-if="!isMobile">
        <transition name="fade" :duration="3000">
          <div v-if="showBG" id="intro">
            <div class="content-part">
              <!-- <div class="btn" id="btn-program" @click="btnProgram">
                PROGRAM
              </div> -->
              <div id="text-Loading" class="text">
                Loading...
              </div>
            </div>
            <div class="content-top">
              <div v-if="db_hmoe.ShowLiveBtn">
                <div
                  id="btn-livestreaming"
                  class="btn"
                  @click="btnLiveStreaming"
                >
                  <font color="red">● </font>
                  <pre></pre>
                  Live Stream
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div v-if="!webglSupport">
      <transition name="fade" :duration="3000">
        <div v-if="showBG" id="intro">
          <div class="content-part">
            <div class="text">
              <p id="Warnning" v-html="warnText"></p>
            </div>

            <div
              id="btn-livestreaming-webglnotsupported"
              class="btn"
              @click="btnLiveStreaming"
            >
              <font color="red">●</font>
              <pre></pre>
              Watch live stream here
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="isMobile">
      <transition name="fade" :duration="3000">
        <div v-if="showBG" id="intro">
          <div class="content-part">
            <div id="Warnning" class="text">
              <p v-html="warnText"></p>
            </div>

            <div id="btn-livestreaming" class="btn" @click="btnLiveStreaming">
              <font color="red">●</font>
              <pre></pre>
              Watch live stream here
            </div>
          </div>
        </div>
      </transition>
    </div>

    <transition name="fade" :duration="3000">
      <div v-if="showIntro" id="intro">
        <div class="overlay-content">
          <div id="btn-fullscreen" class="btn" href="#" @click="setFullscreen">
            <img class="icon" src="@/assets/img/ui-10.png" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Unity from '@/components/UnityContainer'
// eslint-disable-next-line
import { isMobile } from 'mobile-device-detect'
import { mapState } from 'vuex'
import { db } from '@/js/rtdb'
// import { page } from 'vue-analytics'

export default {
  components: { Unity },
  data() {
    return {
      // SRC
      src: '../static/20-10-AF-ev20f1/Build/_FinalBuild.json',
      uloader: '../static/20-10-AF-ev20f1/Build/UnityLoader.js',
      hlsloader: '../static/20-10-AF-ev20f1/TemplateData/hls.min.js',

      // Dev Settings
      dev_isEnvDev: process.env.NODE_ENV === 'development',
      // dev_src: 'static/Build/WebglCtrl-03-b02.json',
      // dev_NoGame: true,
      dev_NoOutro: false,
      dev_ForceOutro: false,

      // firebase rtdb
      db_hmoe: [],

      // Client States
      d: 0,
      leftFulltime: '',
      isGameLoaded: false,
      isGameStarted: false,
      isGameEnd: false,
      downloadProgress: 0,
      introState: true,
      showMobileWarn: isMobile,
      showBG: false,
      showOutro: false,
      introPart: 0,
      pageLoaded: false,
      webglSupport: true,

      leftTime: '',

      browser: {
        isChrome:
          !!window.chrome &&
          (!!window.chrome.webstore || !!window.chrome.runtime),

        isFirefox: typeof InstallTrigger !== 'undefined'
      }

      // // infos
      // warnText: 'The 3D application does not yet support mobile devices. Please revisit with laptop or PC.'
    }
  },
  head() {
    return {
      title: {
        inner: 'EV20f1'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle}`,
          id: 'desc'
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1'
        },
        { property: 'og:title', content: `${this.appTitle} AFUN` },
        {
          property: 'og:image',
          content: '/img/og.jpg'
        }
      ]
    }
  },

  computed: {
    ...mapState('app', ['appTitle']),
    browserSupported() {
      if (this.browser.isChrome || this.browser.isFirefox) return true
      return false
    },
    deviceSupported() {
      if (isMobile) return false
      return true
    },
    isMobile() {
      if (isMobile) return true
      return false
    },
    gameSupported() {
      if (this.webglSupport && this.deviceSupported) return true
      return false
    },
    canLoadGame() {
      if (this.dev_isEnvDev && this.dev_NoGame) return false
      return this.gameSupported
    },
    showWarn() {
      if (!this.deviceSupported || !this.webglSupport) return true
      return false
    },
    showIntro() {
      if (this.introState && this.gameSupported && !this.showOutro) return true
      return false
    },
    warnText() {
      if (!this.deviceSupported) {
        console.log(this.deviceSupported)
        return 'Login with PC or Laptop, mobile devices are not supported'
      }
      if (!this.webglSupport) {
        console.log(this.deviceSupported)
        return 'Your browser is not supported. Chrome & Firefox recommended'
      }
      return ''
    }
  },
  beforeMount() {
    this.GetDB()
    if (this.dev_isEnvDev) {
      if (this.dev_src) this.src = this.dev_src
      if (this.dev_ForceOutro) {
        this.introState = false
        this.showBG = false
        setTimeout(() => (this.showOutro = true), 500)
      }

      console.log(
        'DEV',
        this.browserSupported,
        this.deviceSupported,
        this.gameSupported
      )
    }

    const gl = document.createElement('canvas').getContext('webgl2')
    if (!gl) {
      this.webglSupport = false
    } else {
      this.webglSupport = true
    }
    console.log('👽Webgl 2.0 support:', this.webglSupport)
  },

  mounted() {
    this.$gtag.event('ev20f1 mounted', { method: 'Google' })
    setTimeout(() => (this.showBG = true), 500)

    window.addEventListener(
      'keydown',
      e => {
        if (e.code === 'Escape') this.onEscKey()
      },
      false
    )

    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) this.introState = false
      else this.introState = true
    })
    // check initial states
    // if (!this.gameSupported)
    //   this.introState = false

    //
    // console.log('👽browser isChrome?', vm.$browserDetect.isChrome) //eslint-disable-line
    // dev
  },

  methods: {
    GetDB() {
      db.ref('WebApp/Page_Home').once('value', snapshot => {
        this.db_hmoe = snapshot.val()
      })
    },
    // login () {
    //   this.$gtag.event('login', { method: 'Google' })
    // },
    // track () {
    //   this.$gtag.pageview({
    //     page_path: '/',
    //   })
    // },
    onProgress(progress) {
      // console.log(`onProgress: ${progress}`)
      this.downloadProgress = progress
    },
    onClick() {
      this.$refs.UnityRef.message('object', 'method', 'param')
    },
    onDownloaded() {
      console.log('👽 onGameLoaded')
      this.isGameLoaded = true
    },
    onGameStarted() {
      console.log('👽 onGameStarted')
      this.isGameStarted = true
      this.showBG = false
      setTimeout(() => (this.introState = false), 50000)
      this.$gtag.event('ev20f1 onGameStarted', { method: 'Google' })
    },
    onUnityFocus() {
      console.log('👽 onUnityFocus')
      // this.introState = false
      // const webgl = document.querySelector('#WebglPlayer canvas')
      // this.requestPointerLock(webgl)
    },
    onGameEnd() {
      if (this.dev_NoOutro && this.dev_isEnvDev) return
      console.log('👽 onGameEnd')
      this.isGameEnd = true
      this.showOutro = true
    },
    onEscKey() {
      this.introState = !this.introState
      console.log('👽onEscKey')
    },
    NextPart() {
      this.introPart += 1
      if (this.introPart > 2) this.introPart = 0
    },
    setFullscreen() {
      // this.$refs.UnityRef.fullscreen()
      this.introState = false
      document.body.requestFullscreen()
    },
    btnProgram() {
      window.open(
        'https://www.unsound.pl/en/intermission/events/afterlife-ev20f1-presented-by-naxs-corp-and-meuko-meuko'
      )
    },
    btnEnter() {
      // window.open("/ev20f1", ", _self");
      // window.location.href = "/ev20f1";
      this.$router.push('/ev20f1/')
    },
    btnLiveStreaming() {
      window.open('https://www.twitch.tv/naxscorp')
    }
    // countTime() {
    //   const date = new Date()
    //   const now = date.getTime()

    //   const endDate = new Date("2020-10-10 22:00:00")
    //   const end = endDate.getTime()

    //   this.leftTime = end - now
    //   if (this.leftTime >= 0) {
    //     this.d = Math.floor(this.leftTime / 1000 / 60 / 60 / 24)
    //     this.h = Math.floor(this.leftTime / 1000 / 60 / 60 % 24)
    //     this.m = Math.floor(this.leftTime / 1000 / 60 % 60)
    //     this.s = Math.floor(this.leftTime / 1000 % 60)
    //     if (this.h <= 0) {
    //       this.leftFulltime = `${this.d} Days ${this.m} m ${this.s} s`
    //     } else if (this.h <= 0 && this.m <= 0) {
    //       this.leftFulltime = `${this.d} Days ${this.s} s`
    //     } else {
    //       this.leftFulltime = `${this.d} Days ${this.h} h ${this.m} m ${this.s} s`
    //     }
    //   }
    //   // console.log(this.leftFulltime)
    //   setTimeout(this.countTime, 1000)
    //   return this.leftFulltime

    // },
    // countBool() {
    //   if (this.leftFulltime !== '') {
    //     return true
    //   }
    //   return false
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Poppins:wght@600&display=swap%27');

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay-bg {
  background: rgba(0, 0, 0, 1);
  text-align: center;
  top: 0;
}

.content-part {
  text-align: center;
  .flex-child {
    width: 400px;
  }
}

.overlay-bottom {
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 350px;
  text-align: center;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 60%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 60%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 60%,
    rgba(0, 0, 0, 1) 100%
  );
}

#intro {
  .content-part {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    text-shadow: 0.03em 0.03em #888;
    color: #ffffff;
    position: absolute;
    width: 100%;
    // bottom: 350px;
    top: 68.5%;
    left: 0;
    @media screen and (max-width: 1000px) {
      font-size: 16px;
      // bottom: 73vw;
      top: 61%;
      max-width: 100%;
    }
  }
  a {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .content-top {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    text-shadow: 0.03em 0.03em #888;
    color: #ffffff;
    position: absolute;
    width: 100%;
    // bottom: 350px;
    top: 2%;
    left: 0;
    @media screen and (max-width: 1000px) {
      font-size: 16px;
      // bottom: 73vw;
      top: 61%;
      max-width: 100%;
    }
  }
}

#Warnning {
  position: relative;
  bottom: 1vw;
  font-size: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    // top: 10vh;
    font-size: 3vw;
    height: auto;
    max-width: 100%;
    // top: 80vh;
    // bottom: 30vh;
  }
}

#btn-program {
  position: relative;
  border-style: solid;
  border-width: 2px;
  width: 10vw;
  height: 2.3vw;
  margin: 0 auto;
  padding: 3px 2px;
  // top: 9.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    top: 25vw;
    width: 130px;
    height: 35px;
  }
}

#btn-program:hover {
  background-color: #ffffff;
  color: #222;
  border-color: #fff;
}

#btn-enter {
  position: relative;
  border-style: solid;
  border-width: 2px;
  width: 10vw;
  height: 2.3vw;
  padding: 3px 2px;
  margin: 0 auto;
  top: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    top: 31vw;
    width: 130px;
    height: 35px;
  }
}

#btn-enter:hover {
  background-color: #ffffff;
  color: #222;
  border-color: #fff;
}

#btn-livestreaming {
  position: fixed;
  border-style: solid;
  border-width: 2px;
  // width: auto;
  height: 50px;
  padding: 5px 20px;
  margin: 0 auto;
  top: 2vw;
  right: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    font-size: 10px;
    position: relative;
    border-width: 1px;
    padding: 3px 10px;
    right: 0;
    top: 0vw;
    width: 35vw;
    height: 25px;
  }
}

#btn-livestreaming:hover {
  background-color: #ffffff;
  color: #222;
  border-color: #fff;
}

#btn-livestreaming-webglnotsupported {
  position: relative;
  border-style: solid;
  border-width: 2px;
  width: 18vw;
  height: 50px;
  padding: 5px 15px;
  margin: 0 auto;
  bottom: 1vw;
  // top: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

#btn-livestreaming-webglnotsupported:hover {
  background-color: #ffffff;
  color: #222;
  border-color: #fff;
}

#content-open {
  position: relative;
  font-size: 1.5vh;
  top: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    top: 32vw;
    width: 130px;
    height: 35px;
  }
}

#text-Loading {
  position: relative;
  font-size: 2.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#count-time {
  font-family: 'EB Garamond', serif;
  font-size: 1.5vw;
  text-shadow: 0.03em 0.03em #888;
  position: relative;
  // border-style: solid;
  // border-width: 2px;
  width: 350px;
  height: 50px;
  margin: 0 auto;
  top: 14vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    font-size: 2vh;
    top: 42vw;
    width: 130px;
    height: 35px;
  }
}

#MainBg {
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    position: absolute;
    z-index: -1;
    min-width: 100vw;
    min-height: 100vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .logo {
    position: relative;
    // height: 60vh;
    width: auto;
    margin: auto auto;
    max-width: 90vw;
    @media screen and (max-width: 1000px) {
      // top: 10vh;
      height: auto;
      max-width: 100%;
    }
    img {
      width: 100%;
      height: auto;
      // max-width: 90vw;
    }
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
  }
}
#outro {
  transition-duration: 10s;
  .links {
    margin-bottom: 20vw;
    p {
      margin: 0;
    }
    a {
      display: inline-block;
      margin: 0 10px;
      font-size: 21px;
      color: #cc0033;
    }
  }
  background: rgba(0, 0, 0, 0.95);
  h1,
  h3 {
    margin: 2vw 0 2vw 0;
    color: #cc0033;
  }
  h3 {
    margin: 20vw 0 2vw 0;
    font-size: 32px;
  }
  .overlay-content {
    text-align: center;
    padding: 0 5vw;
    width: 50vw;
    position: relative;
    margin: 0 auto;
    max-width: 800px;
  }
  .logo {
    // height: 100vh;
    img {
      height: 70vh;
      padding: 5vh;
    }
  }
  #meta {
    padding: 10vw 0;
    min-height: 100vh;
    position: relative;
    margin-bottom: 10vh;
    text-align: left;
  }
}

#btn-fullscreen {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  img {
    width: 40px;
    height: 40px;
  }
  .seperator {
    height: 100px;
  }
}

.progress-bar {
  position: fixed;
  width: 100%;
  height: 6px;
  bottom: 0;
  left: 0;
  z-index: 999;
  .progress-fill {
    height: 100%;
    background: #cc0033;
    transition: width 0.1s;
  }
  &.loaded {
    opacity: 0;
    transition: 1s;
    transition-delay: 1s;
    .progress-fill {
      width: 100% !important;
    }
  }
}

.wrapper {
  white-space: pre-wrap;
}
</style>
